var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-title": "作业票起草",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave("PREPARE")
          },
          "head-sign": _vm.headSign,
          "head-submit": function ($event) {
            return _vm.headSubmit(false)
          },
          "head-submitSign": _vm.signSubmit,
        },
      }),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 166px)" } },
        [
          _c("Editor", {
            ref: "editor",
            attrs: { mode: _vm.mode, loadHtml: _vm.templateJson },
            on: {
              docLoaded: _vm.docLoaded,
              onAfterInit: function ($event) {
                return _vm.onAfterInit("editor")
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.roleBox,
            width: "90%",
            top: "10vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.roleBox = $event
            },
            close: function ($event) {
              _vm.roleBox = false
            },
          },
        },
        [
          _vm.roleBox
            ? _c("departmentPersonnel", {
                ref: "departmentPersonnel",
                attrs: { "disabled-list": [] },
              })
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.roleBox = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn")))]
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("\n                确定\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.signBox,
            width: "30%",
            top: "30vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.signBox = $event
            },
            close: function ($event) {
              _vm.signBox = false
            },
          },
        },
        [
          _c("span", [
            _vm._v(_vm._s(_vm.noSign) + "的签署未完成，确认提交审批？"),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.signBox = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn")))]
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveSign } },
                [_vm._v("\n                确定\n            ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("process-user-dialog", {
        ref: "processUser",
        on: { getUserSelection: _vm.handleUserSelection },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.userListShow,
            top: "10vh",
            width: "90%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userListShow = $event
            },
          },
        },
        [
          _c("UserDeptMultipleDialog", {
            on: {
              "select-all-data": _vm.getUserList,
              closeDia: function ($event) {
                _vm.userListShow = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }