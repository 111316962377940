<template>
    <div>
        <head-layout head-title="作业票起草" :head-btn-options="headBtnOptions" @head-save="headSave('PREPARE')"
            @head-sign="headSign" @head-submit="headSubmit(false)" @head-submitSign="signSubmit"></head-layout>
        <div style="height:calc(100vh - 166px)">
            <Editor :mode="mode" ref="editor" :loadHtml="templateJson" @docLoaded="docLoaded"
                @onAfterInit="onAfterInit('editor')" />
        </div>
        <el-dialog title="人员选择" :visible.sync="roleBox" width="90%" top="10vh" @close="roleBox = false">
            <departmentPersonnel ref="departmentPersonnel" v-if="roleBox" :disabled-list="[]">
            </departmentPersonnel>
            <span slot="footer" class="dialog-footer">
                <el-button @click="roleBox = false">{{
            $t("cip.cmn.btn.celBtn")
        }}</el-button>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="submit" type="primary">
                    确定
                </el-button>
            </span>
        </el-dialog>
        <el-dialog title="提示" :visible.sync="signBox" width="30%" top="30vh" @close="signBox = false">
            <span>{{ noSign }}的签署未完成，确认提交审批？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="signBox = false">{{
            $t("cip.cmn.btn.celBtn")
                    }}</el-button>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="saveSign" type="primary">
                    确定
                </el-button>
            </span>
        </el-dialog>
        <process-user-dialog ref="processUser"  @getUserSelection="handleUserSelection"></process-user-dialog>
        <!--当前部门及以下多选弹窗-->
        <el-dialog title="人员选择" :visible.sync="userListShow" top="10vh" width="90%">
          <UserDeptMultipleDialog @select-all-data="getUserList" @closeDia="userListShow = false"></UserDeptMultipleDialog>
        </el-dialog>
    </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import Editor from "@/views/editor/index"
import departmentPersonnel from "@/views/business/training/exam/departmentPersonnel.vue";
import { ticketInventoryDetail, ticketInventorySave, ticketInventorySign, processList, ticketInventoryTask, ticketInventorySubmit, ticketInventoryTaskDetail, ticketSubmit, workTicketControlTree, ticketTemplateDetail } from "@/api/workTicket/index"
import draft from "@/views/plugin/workflow/mixins/draft";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import { mapGetters } from "vuex";
import ProcessUserDialog from "@/views/business/components/processUserDialog.vue";
import {startProcess} from "@/api/plugin/workflow/process";
import UserDeptMultipleDialog from "@/views/components/UserDeptDialog/UserDeptMultipleDialog";
export default {
    data() {
        return {
            mode: 'form',
            type: '',
            templateInfo: {},
            roleBox: false,
            processId: '',
            taskId: "",
            isSubmit: false,
            userListShow: false,
            formProcess: {
                processId: '',
                deptId: '',
                processBusinessName: '',
                assignee:'',
                assigneeName:''
            },
            signBox: false,
            noSign: '',
            templateJson: '',
            controlTypes: [],
        };
    },
    components: {ProcessUserDialog, HeadLayout, Editor, departmentPersonnel, UserDeptMultipleDialog},
    mixins: [exForm, draft],
    computed: {
        ...mapGetters(['userInfo']),
        headBtnOptions() {
            if (this.type == 'view' || this.type == 'workflow') {
                return []
            } else if (this.type == 'sign') {
                return [{
                    label: '保存',
                    emit: "head-save"
                },
                {
                    label: '提交',
                    emit: "head-submitSign"
                }]
            } else if (this.type == 'approve') {
                return [{
                    label: '保存',
                    emit: "head-save"
                },
                {
                    label: '审批',
                    emit: "head-submit"
                }]
            } else {
                return [{
                    label: '保存',
                    emit: "head-save"
                }, {
                    label: '转发签署',
                    emit: "head-sign"
                },
                {
                    label: '提交',
                    loading: this.isSubmit,
                    emit: "head-submit"
                }]
            }

        },
    },
    created() {
        let that = this
        let url = window.location.href;
        let p = url.split("?")[1];
        let templateId = ''
        let type = ''
        let id = ''
        let taskId = ""
        if (p) {
            let query = new URLSearchParams(p);
            id = query.get("id");
            type = query.get("type");
            templateId = query.get("templateId");
            taskId = query.get("taskId");
        } else {
            id = this.$route.query.id;
            type = this.$route.query.type;
            templateId = this.$route.query.templateId;
            taskId = this.$route.query.taskId;
        }
        this.taskId = taskId
        this.type = type
        if (["edit", "view", "sign", "approve", "workflow"].includes(type)) {
            if (this.type == 'view') {
                this.mode = 'readonly'
            }
            if (this.taskId) {
                ticketInventoryTaskDetail(this.taskId).then(res => {
                    this.getDetail(res.data.data.ticketId)
                })
            } else {
                this.getDetail(id)
            }
        }
        if (this.type == 'add') {
            ticketTemplateDetail(templateId).then(res => {
                let templateInfo = res.data.data
                that.templateInfo = {
                    organizationId: this.userInfo.dept_id,
                    templateCode: templateInfo.code,
                    templateId: templateInfo.id,
                    type: templateInfo.ticketType,
                    version: templateInfo.version,
                    wtJson: templateInfo.templateJson,
                    processKey: templateInfo.processKey
                }
                that.templateJson = that.htmlDecode(that.templateInfo.wtJson);
                that.getProcessList()
                that.getControlTree()
            })
        }
    },
    mounted() {
        window.addEventListener("message", this.receiveMessage, false);
    },

    methods: {
      setCurrent(row){
        this.$refs.processUserDept.$refs.gridLayout.$refs.grid.setCurrentRow(row);
      },
        receiveMessage(event) {
            if (event.data.type == 'workTicket') {
                this.headSave();
            }
        },
        onAfterInit(editor) {
            const iframe = this.$refs[editor].$el.firstChild;
            let iframeDocument = iframe.contentDocument;
            if (iframeDocument) {
                var element = iframeDocument.getElementById('_toolbar');
                if (element) element.style.display = 'none';
                var main = iframeDocument.getElementById('_main');
                if (main) main.style.top = 0; main.style.height = '100%'

            }
        },
        docLoaded() {
            this.$refs.editor.editor.setBindObject(this.templateInfo.code, 'ticketNum')
        },
        getControlTree() {
            workTicketControlTree(this.templateInfo.type).then(res => {
                if (res.data.code == 200) {
                    res.data.data.forEach(item => {
                        this.controlTypes.push(item.enName)
                    })
                }
            })
        },
        getProcessList() {
            this.isSubmit = true;
            processList(1, 9999).then(res => {
                if (res.data.code == 200) {
                    let list = res.data.data.records.filter(
                        (item) => item.category == '作业票'
                    )
                    list.forEach(item => {
                        if (item.key == this.templateInfo.processKey) {
                            this.processId = item.id
                        }
                    })
                }
            }).finally(() => {
                this.isSubmit = false;
            })
        },
        getDetail(templateId) {
            ticketInventoryDetail(templateId).then(res => {
                if (res.data.code == 200) {
                    this.templateInfo = res.data.data;
                    this.templateJson = this.htmlDecode(this.templateInfo.wtJson);
                    this.getControlTree()
                    this.getProcessList()
                }
            })
        },
        htmlEncode(html) {
            //1.首先动态创建一个容器标签元素，如DIV
            let temp = document.createElement("div");
            //2.然后将要转换的字符串设置为这个元素的innerText或者textContent
            (temp.textContent != undefined) ? (temp.textContent = html) : (temp.innerText = html);
            //3.最后返回这个元素的innerHTML，即得到经过HTML编码转换的字符串了
            let output = temp.innerHTML;
            temp = null;
            return output;
        },
        htmlDecode(text) {
            //1.首先动态创建一个容器标签元素，如DIV
            let temp = document.createElement("div");
            //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
            temp.innerHTML = text;
            //3.最后返回这个元素的innerText或者textContent，即得到经过HTML解码的字符串了。
            let output = temp.innerText || temp.textContent;
            temp = null;
            return output;
        },
        async headSave(type, showMessage) {
            // this.templateInfo.workContent = this.$refs.editor.editor.getFieldValueById('workContent');
            // this.templateInfo.workExecutionTime = this.$refs.editor.editor.getFieldValueById('workExecutionTime');
            // this.templateInfo.workSupervisor = this.$refs.editor.editor.getFieldValueById('workSupervisor');
            this.controlTypes.forEach(item => {
              if(item === 'workSupervisor'){
                this.templateInfo.workSupervisorId = this.$refs.editor.editor.$("#workSupervisor").attr('userIds');
              }
                this.templateInfo[item] = this.$refs.editor.editor.getFieldValueById(item);
            })
            this.templateInfo.businessStatus = this.templateInfo.businessStatus ? this.templateInfo.businessStatus : type
            this.templateInfo.wtJson = this.htmlEncode(this.$refs.editor.editor.getHtml())
            await ticketInventorySave(this.templateInfo).then(res => {
                if (res.data.code == 200) {
                    this.templateInfo = res.data.data;
                    this.templateJson = this.htmlDecode(this.templateInfo.wtJson);
                    if (!showMessage) {
                        this.$message.success(
                            '保存成功'
                        );
                    }
                }
            })
        },
        headSign() {
            this.headSave('PREPARE', true);
            this.userListShow = true
        },
        getUserList(data) {
            //多选人员处理
            // let confirmTheSubmission = this.$refs.departmentPersonnel.confirmTheSubmission();
            if (data.selectAllData.length == 0) {
                this.$message({
                    type: 'warning',
                    message: '请选择转发签署人员'
                })
                return;
            }
            let isSelf = data.selectAllData.find(item => item.id == this.userInfo.user_id);
            if (isSelf) {
                this.$message({
                    type: 'warning',
                    message: '转发签署人员不能选择自己！'
                })
                return;
            }
            this.userListShow = false
            let userList = []
            data.selectAllData.forEach(item => {
                userList.push({
                    userId: item.id,
                    realName: item.realName,
                    deptId: item.treeDeptId
                })
            })
            let params = {
                userList,
                workTicket: {
                    id: this.templateInfo.id,
                    code: this.templateInfo.code,
                    type: this.templateInfo.type,
                    workContent: this.templateInfo.workContent,
                    createUserName: this.templateInfo.createUserName
                }
            }
            ticketInventorySign(params).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('转发签署成功')
                    this.$router.$avueRouter.closeTag();
                    this.$router.back();
                }
            })
        },
        handleUserSelection(userIds){
            this.formProcess.assignee = userIds;
            this.startProcess(true)
        },
      startProcess(flag){
        this.handleStartProcess6(true,flag).then((res) => {
          this.templateInfo.processInsId = res.data.data;
          this.templateInfo.status = 2;
          this.templateInfo.businessStatus = 'APPROVING';
          ticketInventorySave(this.templateInfo).then(res => {
            if (res.data.code == 200) {
              this.templateInfo = res.data.data;
              this.templateJson = this.htmlDecode(this.templateInfo.wtJson);
              this.$message.success(
                this.$t("cip.cmn.msg.success.operateSuccess")
              );
              this.$router.$avueRouter.closeTag();
              this.$router.back();
            }
          })

        });
      },
        async headSubmit(flag) {
            if (this.$refs.editor.editor.validate()) {
                await this.headSave('APPROVING', true);
                ticketInventoryTask(this.templateInfo.id).then(res => {
                    if (res.data.data === true) {
                        this.formProcess.id = this.templateInfo.id;
                        this.formProcess.processId = this.processId;
                        this.formProcess.deptId = this.templateInfo.organizationId;
                        this.formProcess.processBusinessName = this.templateInfo.workContent;
                       this.startProcess(flag)
                    } else {
                        this.noSign = res.data.data.join(',');
                        this.signBox = true
                    }
                })

            }
        },
        async saveSign() {
            this.signBox = false;
            if (this.$refs.editor.editor.validate()) {
                await this.headSave('APPROVING', true);
                this.formProcess.id = this.templateInfo.id;
                this.formProcess.processId = this.processId;
                this.formProcess.deptId = this.templateInfo.organizationId;
                this.formProcess.processBusinessName = this.templateInfo.workContent;
                this.handleStartProcess6(true).then((res) => {
                    this.templateInfo.processInsId = res.data.data;
                    this.templateInfo.status = 2;
                    this.templateInfo.businessStatus = 'APPROVING';
                    ticketSubmit(this.templateInfo).then(res => {
                        if (res.data.code == 200) {
                            this.templateInfo = res.data.data;
                            this.templateJson = this.htmlDecode(this.templateInfo.wtJson);
                            this.$message.success(
                                this.$t("cip.cmn.msg.success.operateSuccess")
                            );
                            this.$router.$avueRouter.closeTag();
                            this.$router.back();
                        }
                    })

                });


            }
        },
        async signSubmit() {
            this.templateInfo.taskId = this.taskId;
            this.templateInfo.workContent = this.$refs.editor.editor.getFieldValueById('workContent');
            this.templateInfo.workExecutionTime = this.$refs.editor.editor.getFieldValueById('workExecutionTime');
            this.templateInfo.workSupervisor = this.$refs.editor.editor.getFieldValueById('workSupervisor');
            this.templateInfo.wtJson = this.htmlEncode(this.$refs.editor.editor.getHtml())
            ticketInventorySubmit(this.templateInfo).then(res => {
                if (res.data.code == 200) {
                    this.$message.success(
                        this.$t("cip.cmn.msg.success.operateSuccess")
                    );
                    this.$router.$avueRouter.closeTag();
                    this.$router.back();
                }
            })
        }
    },
    destroyed() {
        // 清除popstate事件 否则会影响到其他页面
        window.removeEventListener("message", this.receiveMessage, false);
    },
}
</script>

<style scoped lang='scss'></style>
